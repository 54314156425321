var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-container"},[_c('md-app',[(
        this.$router.currentRoute.name !== 'Login' &&
        this.$router.currentRoute.name !== 'Validación' &&
        this.$router.currentRoute.name !== 'Recuperación de contraseña' &&
        this.$router.currentRoute.name !== 'Resultado Inscripción' &&
        this.$router.currentRoute.name !== 'Inscríbete' &&
        this.$router.currentRoute.name !== 'Error'
      )?_c('md-app-toolbar',{staticClass:"md-primary",attrs:{"md-elevation":"0"}},[(!_vm.menuVisible)?_c('md-button',{staticClass:"md-icon-button",on:{"click":_vm.toggleMenu}},[_c('md-icon',[_vm._v("menu_open")])],1):_vm._e(),(!_vm.menuVisible)?_c('span',[_c('img',{staticClass:"white-logo",attrs:{"alt":"Mas Madrid logo fff","width":"80px","src":require("./assets/624px-Logo_Mas_Madrid.png")}})]):_vm._e(),_c('div',{staticClass:"demo-badge"},[_c('span',{staticClass:"md-title md-alignment-right"},[_vm._v("Hola, "+_vm._s(this.authService.getDecodedJwtToken(this.$store.state.auth.user).sub))])]),_c('div',[_c('md-button',{staticClass:"md-icon-button",attrs:{"to":"/menu-principal"}},[_c('md-icon',[_vm._v("menu")])],1),_c('md-button',{staticClass:"md-icon-button",on:{"click":_vm.logout}},[_c('md-icon',[_vm._v("logout")])],1)],1)],1):_vm._e(),(
        this.$router.currentRoute.name !== 'Login' &&
        this.$router.currentRoute.name !== 'Validación' &&
        this.$router.currentRoute.name !== 'Recuperación de contraseña' &&
        this.$router.currentRoute.name !== 'Resultado Inscripción' &&
        this.$router.currentRoute.name !== 'Error' &&
        this.$router.currentRoute.name !== 'Inscríbete'
      )?_c('md-app-drawer',{attrs:{"md-active":_vm.menuVisible,"md-persistent":"mini"},on:{"update:mdActive":function($event){_vm.menuVisible=$event},"update:md-active":function($event){_vm.menuVisible=$event}}},[_c('md-toolbar',{staticClass:"md-transparent",attrs:{"md-elevation":"0"}},[_c('span',[_c('img',{staticClass:"m-1",attrs:{"alt":"Mas Madrid logo","width":"80px","src":require("./assets/logo.png")}})]),_c('div',{staticClass:"md-toolbar-section-end"},[_c('md-button',{staticClass:"md-icon-button",on:{"click":_vm.toggleMenu}},[_c('md-icon',[_vm._v("keyboard_arrow_left")])],1)],1)]),_c('div',{staticClass:"list"},[_c('md-list',{attrs:{"md-expand-single":_vm.expandSingle}},[_c('md-list-item',{attrs:{"to":{ name: 'Inicio' }}},[_c('md-icon',{staticClass:"color1"},[_vm._v("home")]),_c('span',{staticClass:"md-list-item-text"},[_vm._v("Inicio")])],1),(
              this.authService.getDecodedJwtToken(this.$store.state.auth.user).rol ===
                'ROLE_SUPER_ADMIN' ||
              this.authService.getDecodedJwtToken(this.$store.state.auth.user).rol ===
                'ROLE_ADMIN' ||
              this.authService.getDecodedJwtToken(this.$store.state.auth.user).rol ===
                'ROLE_RESPONSABLE' ||
              this.authService.getDecodedJwtToken(this.$store.state.auth.user).rol ===
                'ROLE_ENLACE'
            )?_c('md-list-item',{attrs:{"to":"/comunicaciones"}},[_c('md-icon',{staticClass:"color1"},[_vm._v("mail")]),_c('span',{staticClass:"md-list-item-text"},[_vm._v("Comunicaciones")])],1):_vm._e()],1)],1)],1):_vm._e(),(
        this.$router.currentRoute.name !== 'Login' &&
        this.$router.currentRoute.name !== 'Validación' &&
        this.$router.currentRoute.name !== 'Recuperación de contraseña' &&
        this.$router.currentRoute.name !== 'Resultado Inscripción' &&
        this.$router.currentRoute.name !== 'Error' &&
        this.$router.currentRoute.name !== 'Inscríbete'
      )?_c('md-app-content',[_c('router-view')],1):_vm._e(),(
        this.$router.currentRoute.name == 'Login' ||
        this.$router.currentRoute.name == 'Validación' ||
        this.$router.currentRoute.name == 'Recuperación de contraseña' ||
        this.$router.currentRoute.name == 'Resultado Inscripción' ||
        this.$router.currentRoute.name == 'Error' ||
        this.$router.currentRoute.name == 'Inscríbete'
      )?_c('md-app-content',{staticClass:"public-app-content"},[_c('router-view')],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }