/* eslint-disable class-methods-use-this */
import axios from 'axios';
import authHeader from '@/services/auth-header';

const API_URL = `${process.env.VUE_APP_API_URL}`;

class AffiliateService {
  getLoggedAffiliate() {
    return axios
      .get(`${API_URL}logged/affiliate/`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }
}

export default new AffiliateService();
